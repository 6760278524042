import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { graphql, navigate, Link } from "gatsby";
import styled from "styled-components";
import { Visible } from "react-awesome-styled-grid";

import { SettingsStore, SettingsShape } from "src/stores/Settings";
import { PageStore } from "src/stores/Page";
import { LatestCardsStore } from "src/stores/LatestCards";
import getLocalizedSlug from "src/utils/getLocalizedSlug";

import Layout from "src/layouts/Main";
import { AppContext } from "src/layouts/AppState";
import SEO from "src/atoms/SEO";
import {
  CategoryList,
  CategoryOverview,
  OverviewGridItem,
} from "src/organisms/ProductCategories";
import { trackLayoutChange } from "src/organisms/ProductCategories/trackEvents";
import OverviewItem from "src/molecules/ProductCategories/OverviewItem";

import ScreenReaderTitle from "src/atoms/ScreenReaderTitle";
import FloatingButton, { SIZES } from "src/molecules/FloatingButton";
import { icons } from "src/styles/variables";
import BurgerVertical from "src/atoms/Vectors/Desktop/BurgerVertical";
import NineDots from "src/atoms/Vectors/Desktop/NineDots";
import BurgerHorizontal from "src/atoms/Vectors/Mobile/BurgerHorizontal";
import FourSquares from "src/atoms/Vectors/Mobile/FourSquares";
import { getSlugForCategory } from "src/utils/slugger";
import { ORIGINS as FLAVOR_PAGE_ORIGINS } from "./FlavorPage";
import breadcrumb from "src/utils/breadcrumb";
import { TYPES as PICTURE_TYPES } from "src/atoms/Picture";
import getImage from "src/utils/getImage";
import getMetaImageFields from "src/utils/getMetaImageFields";
import trackEvent from "./trackEvent";

const VIEW_TYPES = {
  list: "List View",
  grid: "Grid View",
};

const CategoryPage = ({
  data,
  pageContext: { locale, localizedSlugs, hreflang },
  path,
  location,
}) => {
  const ctx = useContext(AppContext) || {};
  const { isProducstLayoutOverview, setIsProducstLayoutOverview } = ctx;
  const comesFromFlavor =
    location && location.state && location.state.isFromFlavor;
  const shouldAnimate = comesFromFlavor && !isProducstLayoutOverview[path];

  if (!data) {
    throw new Error(`Page query failed: reason unknown`);
  }
  const settings = {
    ...data.contentfulSettings,
    locale,
    localizedSlugs,
  };
  const categoryList = data.contentfulProductCategoryList;
  const defaultView = categoryList.defaultView || VIEW_TYPES.list;

  const categories = (categoryList.categories || []).filter((category) => {
    // Check for locale exclusive categories (multi lang but it's exclusive to one locale)
    // Current exceptions: categories - /shot, /smoothie, /rostlinna, exlusive to - cs-CZ, other locales: sk
    const exceptions = ["/shot", "/smoothie", "/rostlinna"];
    if (
      locale.currentLocaleCode === "sk" &&
      exceptions.includes(category.slug)
    ) {
      return false;
    }

    const flavors = category.subcategories.reduce((acc, subcategory) => {
      return [...acc, ...subcategory.flavors];
    }, []);

    if (flavors.length < 1) {
      console.warn(
        `Skipping Category ${category.slug} because it has no products`
      );
    }

    return flavors.length > 0;
  });

  const selectCategory = (slug) => {
    navigate(slug, {
      state: {
        from: FLAVOR_PAGE_ORIGINS.parentCategory,
        prev: null,
      },
    });
  };

  const fullUrl = `${locale.currentRegion.url}${path}`;

  const jsonld = [breadcrumb([{ name: "Category list page", url: fullUrl }])];

  const pageMetadata = {
    ...categoryList.seo,
    image:
      categoryList.seo.image &&
      getMetaImageFields(categoryList.seo.image, PICTURE_TYPES.fixed),
  };

  const updateLayout = (path, value) => {
    const layouts = { ...isProducstLayoutOverview };
    layouts[path] = value;

    setIsProducstLayoutOverview(layouts);
  };

  useEffect(() => {
    if (isProducstLayoutOverview[path] === undefined) {
      updateLayout(path, defaultView !== VIEW_TYPES.list);
    }
  }, []);

  useEffect(() => {
    window.tc_vars = {
      country: locale.currentRegion.region,
      environment: process.env.GATSBY_TRACKING_ENV,
      page_category: "Product Category List",
      page_name: categoryList.seo.title,
      page_type: "Category Page",
      page_subcategory: "",
      page: path,
      work_env: process.env.GATSBY_TRACKING_ENV,
      brand: "Activia",
    };
    window.tC?.container?.reload({ events: { page_view: [{}, {}] } });

    trackEvent();
    //console.log('window.tc_vars category page',window.tc_vars);
  }, []);

  return (
    <SettingsStore value={settings}>
      <SEO
        path={path}
        meta={pageMetadata}
        jsonld={jsonld}
        hreflang={hreflang}
        localizedSlugs={localizedSlugs}
      />
      {/* <ScreenReaderTitle>{pageMetadata.title}</ScreenReaderTitle> */}
      <LatestCardsStore {...data}>
        <PageStore
          initOverride={{
            transparentHeader:
              isProducstLayoutOverview && !isProducstLayoutOverview[path],
            visibleHeader: !shouldAnimate,
          }}
        >
          <Layout header hideHeaderOnScroll={false} locale={locale}>
            <CategoryListContainer>
              <ScreenReaderTitle>{pageMetadata.title}</ScreenReaderTitle>
              <div style={{ display: "none" }}>
                {/* encourage prefetching with hidden Links */}
                {categories.map((category, key) => (
                  <Link
                    key={key}
                    to={getLocalizedSlug(
                      getSlugForCategory(category, categoryList),
                      locale
                    )}
                  />
                ))}
              </div>
              {isProducstLayoutOverview && isProducstLayoutOverview[path] ? (
                <CategoryOverview>
                  {categories.map((category, key) => (
                    <OverviewGridItem key={key}>
                      <OverviewItem
                        title={category.title}
                        description={category.subtitle}
                        image={getImage(
                          category.overviewMedia,
                          PICTURE_TYPES.fluid
                        )}
                        slug={getLocalizedSlug(
                          getSlugForCategory(category, categoryList),
                          locale
                        )}
                        onClick={() =>
                          selectCategory(
                            getLocalizedSlug(
                              getSlugForCategory(category, categoryList),
                              locale
                            )
                          )
                        }
                      />
                    </OverviewGridItem>
                  ))}
                </CategoryOverview>
              ) : (
                <CategoryList
                  shouldAnimate={shouldAnimate}
                  categories={categories.map((category) => ({
                    title: category.title,
                    subtitle: category.subtitle,
                    slug: getLocalizedSlug(
                      getSlugForCategory(category, categoryList),
                      locale
                    ),
                    image: getImage(
                      category.listViewMedia,
                      PICTURE_TYPES.fluid
                    ),
                    subcategoriesCollection: category.subcategoriesCollection,
                  }))}
                  selectCategory={selectCategory}
                  hasInvertedSizes={categoryList.hasInvertedSizes}
                />
              )}
              <ButtonContainer>
                <StyledFloatingButton
                  size={SIZES.medium}
                  onClick={() => {
                    trackLayoutChange();
                    updateLayout(
                      path,
                      isProducstLayoutOverview &&
                        !isProducstLayoutOverview[path]
                    );
                  }}
                >
                  {isProducstLayoutOverview &&
                  isProducstLayoutOverview[path] ? (
                    <>
                      <Visible xs sm md>
                        <BurgerHorizontal size={icons.m} />
                      </Visible>
                      <Visible lg xl>
                        <BurgerVertical size={icons.m} />
                      </Visible>
                    </>
                  ) : (
                    <>
                      <Visible xs sm md>
                        <FourSquares size={icons.m} />
                      </Visible>
                      <Visible lg xl>
                        <NineDots size={icons.m} />
                      </Visible>
                    </>
                  )}
                </StyledFloatingButton>
              </ButtonContainer>
            </CategoryListContainer>
          </Layout>
        </PageStore>
      </LatestCardsStore>
    </SettingsStore>
  );
};

const CategoryListContainer = styled.div`
  position: relative;
`;

const ButtonContainer = styled.div`
  width: 100%;
  position: sticky;
  bottom: 80px;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  margin-top: -80px;
  margin-bottom: 80px;
  height: 0;
`;

const StyledFloatingButton = styled(FloatingButton)`
  margin-right: 30px;
`;

CategoryPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.shape({
    contentfulSettings: PropTypes.shape(SettingsShape).isRequired,
    contentfulProductCategoryList: PropTypes.shape({
      defaultView: PropTypes.string,
      hasInvertedSizes: PropTypes.bool,
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          subtitle: PropTypes.string,
          image: PropTypes.object,
          subcategories: PropTypes.arrayOf(
            PropTypes.shape({
              flavors: PropTypes.arrayOf(
                PropTypes.shape({
                  slug: PropTypes.string.isRequired,
                }).isRequired
              ),
            }).isRequired
          ).isRequired,
        }).isRequired
      ),
      seo: PropTypes.object.isRequired,
    }),
  }),

  pageContext: PropTypes.shape({
    locale: PropTypes.object.isRequired,
    localizedSlugs: PropTypes.instanceOf(Array),
    hreflang: PropTypes.object,
  }).isRequired,
  path: PropTypes.string.isRequired,
};

export const pageQuery = graphql`
  query getCategoryPage($nodeLocale: String!, $id: String!, $tag: String!) {
    contentfulSettings(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
    ) {
      ...SettingsFields
    }

    contentfulProductCategoryList(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
      contentful_id: { eq: $id }
    ) {
      ...CategoryList
    }
    latestEditorialPhotography: allContentfulEditorialPhotographyWithCard(
      limit: 4
      sort: { order: DESC, fields: updatedAt }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...EditorialPhotographyCard
        updatedAt
      }
    }

    latestEditorialIllustration: allContentfulEditorialIllustrationWithCard(
      limit: 4
      sort: { order: DESC, fields: updatedAt }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...EditorialIllustrationCard
        updatedAt
      }
    }

    latestInfluencer: allContentfulInfluencerWithCard(
      limit: 4
      sort: { order: DESC, fields: updatedAt }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...InfluencerCard
        updatedAt
      }
    }

    latestRecipe: allContentfulRecipeWithCard(
      limit: 4
      sort: { order: DESC, fields: updatedAt }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...RecipeCard
        updatedAt
      }
    }

    latestFlavor: allContentfulProductFlavorWithCard(
      limit: 4
      sort: { order: DESC, fields: updatedAt }
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...FlavorCard
        updatedAt
      }
    }
  }
`;

export default CategoryPage;
